import React, { useContext, useState } from "react";
import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import { MdLocationOn, MdMailOutline } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";

import Markerr from "../assets/svg/icons/marker.svg";

const MyIcon = {
  url: Markerr, // Nuoroda į jūsų SVG failą
  scaledSize: new window.google.maps.Size(80, 80), // Dydis, kuriuo bus rodoma ikona
};

const MapComponent = withGoogleMap((props) => (
  <GoogleMap
    options={{
      disableDefaultUI: true,
      styles: [
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#F5F5F5", // Žemėlapio fono spalva
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff", // Vandens telkinių spalva
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ef0303", // Kelių spalva
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#444444", // POI spalva
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#F5F5F5", // Administracinio spalvos spalva
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#444444", // Tranzito spalva
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#000000", // Teksto spalva
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff", // Teksto išvaizdos spalva
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off", // Ikonų matomumas
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "off", // Parkų spalva
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#F5F5F5", // Greitkelių spalva
            },
            {
              lightness: 25,
            },
          ],
        },
      ],
      containerProps: {
        style: {
          borderRadius: "20px", // Border radius stilius
        },
      },
    }}
    defaultCenter={props.center}
    defaultZoom={props.zoom}
  >
    <Marker position={props.center} icon={MyIcon}>
      <InfoWindow>
        <div>
          <p>{props.address}</p>
        </div>
      </InfoWindow>
    </Marker>
  </GoogleMap>
));

function Options() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${name}, Email: ${email}, Message: ${message}`);
    // Siunčiame formos duomenis
  };

  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  const center = { lat: 64.1250049, lng: -21.7986923 };
  const zoom = 13.4;
  return (
    <>
      <Container>
        <Row>
          <Col lg={6}>
            <p style={{ marginTop: 20, textTransform: "uppercase" }}>
              {translations[language].location}
            </p>
            <h2 style={{ fontWeight: 300, marginBottom: 10 }}>
              {translations[language].locationTitle}
            </h2>
            <div style={{ height: "300px", width: "100%", paddingTop: 10 }}>
              <MapComponent
                center={center}
                zoom={zoom}
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
              />
            </div>
          </Col>
          <Col lg={6}>
            <p style={{ marginTop: 20, textTransform: "uppercase" }}>
              {translations[language].contacts}
            </p>
            <h2 style={{ fontWeight: 300 }}>
              {translations[language].contactsTitle}
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    padding: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <MdLocationOn size={20} color="#EF0303" />

                  <a
                    style={{ color: "#000" }}
                    href="https://www.google.com/maps/place/Vi%C3%B0arh%C3%B6f%C3%B0i+3,+110+Reykjav%C3%ADk/@64.1250049,-21.7986923,17z/data=!3m1!4b1!4m6!3m5!1s0x48d67386aa72824d:0x50b3fea18abfbf51!8m2!3d64.1250026!4d-21.7961174!16s%2Fg%2F11c17bq32z"
                    target="_blank"
                  >
                    <span style={{ marginLeft: "3px" }}>
                      Viðarhöfði 3, Reykjavík
                    </span>
                  </a>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    marginTop: 5,
                    padding: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <MdMailOutline size={20} color="#EF0303" />
                  <a
                    style={{ color: "#000" }}
                    href="mailto:info@titan1.is"
                    target="_blank"
                  >
                    <span style={{ marginLeft: "3px" }}>info@titan1.is</span>
                  </a>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    marginTop: 5,
                    padding: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <TbPhoneCall size={20} color="#EF0303" />
                  <a
                    style={{ color: "#000" }}
                    href="tel:+3544541720"
                    target="_blank"
                  >
                    <span style={{ marginLeft: "3px" }}>+354 454 17 20</span>
                  </a>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    marginTop: 5,
                    padding: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <TbPhoneCall size={20} color="#EF0303" />
                  <a
                    style={{ color: "#000" }}
                    href="tel:+3548441720"
                    target="_blank"
                  >
                    <span style={{ marginLeft: "3px" }}>+354 844 17 20</span>
                  </a>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    marginTop: 5,
                    paddingLeft: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {translations[language].time}
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div
                  style={{
                    background: "#f3f3f3",
                    color: "#000",
                    width: "100%",
                    fontSize: 20,
                    marginTop: 5,
                    paddingLeft: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {translations[language].saturday}
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Options;
