import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";

const CardsSection = ({ textShow, dekk, scan, hideServices }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  const cards = [
    {
      icon: require("../assets/svg/icons/icon_car.svg").default,
      title: translations[language].services.inspection,
      text: translations[language].services.text,
    },
    {
      icon: require("../assets/svg/icons/icon_fix_hand.svg").default,
      title: translations[language].services.belt,
      text: translations[language].services.text2,
    },
    {
      icon: require("../assets/svg/icons/icon_car_up.svg").default,
      title: translations[language].services.equipment,
      text: translations[language].services.text3,
    },
    {
      icon: require("../assets/svg/icons/oil.svg").default,
      title: translations[language].oilChange.title,
      text: translations[language].oilChange.description,
    },
    {
      icon: require("../assets/svg/icons/wiper.svg").default,
      title: translations[language].wiperReplacement.title,
      text: translations[language].wiperReplacement.description,
    },
    {
      icon: require("../assets/svg/icons/shock.svg").default,
      title: translations[language].shockAbsorberReplacement.title,
      text: translations[language].shockAbsorberReplacement.description,
    },
    {
      icon: require("../assets/svg/icons/spring.svg").default,
      title: translations[language].springReplacement.title,
      text: translations[language].springReplacement.description,
    },
    {
      icon: require("../assets/svg/icons/bearing.svg").default,
      title: translations[language].wheelBearingReplacement.title,
      text: translations[language].wheelBearingReplacement.description,
    },
  ];

  const tires = [
    {
      icon: require("../assets/svg/icons/tires_01.svg").default,
      title: translations[language].tires_01,
      text: translations[language].tires_01_d,
    },
    {
      icon: require("../assets/svg/icons/tires_03.svg").default,
      title: translations[language].workers,
      text: translations[language].workers_d,
    },
    {
      icon: require("../assets/svg/icons/hotel.svg").default,
      title: translations[language].tireStorage,
      text: translations[language].tireStorageD,
    },
  ];

  const pc = [
    {
      icon: require("../assets/svg/icons/carcheck.svg").default,
      title: translations[language].electronicDiagnostic.title,
      text: translations[language].electronicDiagnostic.description,
    },
    {
      icon: require("../assets/svg/icons/diagnosticEngine.svg").default,
      title: translations[language].engineDiagnostic.title,
      text: translations[language].engineDiagnostic.description,
    },
    {
      icon: require("../assets/svg/icons/brake.svg").default,
      title: translations[language].brakeSystemDiagnostic.title,
      text: translations[language].brakeSystemDiagnostic.description,
    },
    {
      icon: require("../assets/svg/icons/transmission.svg").default,
      title: translations[language].transmissionDiagnostic.title,
      text: translations[language].transmissionDiagnostic.description,
    },
  ];

  const Card = ({ icon, title, text }) => {
    return (
      <div className="card-container">
        <div className="card">
          <div
            style={{
              width: "100%",
              height: "150px",
              position: "relative",
              marginTop: 15,
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="card-icon"
                style={{
                  backgroundImage: `url(${icon})`,
                  backgroundSize: "120px 200px",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </div>
          </div>
          <div className="text-center mt-2">
            <h2 className="card-title">{title}</h2>
            <p className="card-text">{text}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Container>
      {hideServices && (
        <Row>
          {!dekk && !scan && textShow && (
            <Container>
              <p style={{ marginTop: 0, textTransform: "uppercase" }}>
                {translations[language].menu.services}
              </p>
              <h2 style={{ fontWeight: 300 }}>
                {translations[language].servicesTitle}
              </h2>
            </Container>
          )}
          {dekk && (
            <>
              <Container>
                <p style={{ marginTop: 0, textTransform: "uppercase" }}>
                  {translations[language].menu.tires}
                </p>
                <h2 style={{ fontWeight: 300 }}>
                  {translations[language].tiresTitle}
                </h2>
              </Container>
            </>
          )}
          {scan && (
            <>
              <Container>
                <p style={{ marginTop: 0, textTransform: "uppercase" }}>
                  {translations[language].menu.analysis}
                </p>
                <h2 style={{ fontWeight: 300 }}>
                  {translations[language].pc_c}
                </h2>
              </Container>
            </>
          )}
          {!dekk && !scan && (
            <>
              {cards.map((card, index) => (
                <Col lg={3} md={6} sm={12} key={index}>
                  <Card icon={card.icon} title={card.title} text={card.text} />
                </Col>
              ))}
            </>
          )}
          {dekk && (
            <>
              {tires.map((card, index) => (
                <Col lg={4} md={4} sm={12} key={index}>
                  <Card icon={card.icon} title={card.title} text={card.text} />
                </Col>
              ))}
            </>
          )}
          {scan && (
            <>
              {pc.map((card, index) => (
                <Col lg={3} md={6} sm={12} key={index}>
                  <Card icon={card.icon} title={card.title} text={card.text} />
                </Col>
              ))}
            </>
          )}
        </Row>
      )}
    </Container>
  );
};

export default CardsSection;
