import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import toolsIcon from "../assets/svg/icons/tools.svg";

import Gallery from "./Gallery";

import { FaCircle } from "react-icons/fa";

import Options from "./Options";

import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";

function Services2() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fbackground_04.svg?alt=media&token=3e64c03b-e36a-46f4-b85e-14d7d35eb950";

    img.onload = () => {
      setIsImageLoaded(true);
    };
  }, []);

  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${
            isImageLoaded
              ? "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fbackground_04.svg?alt=media&token=3e64c03b-e36a-46f4-b85e-14d7d35eb950"
              : ""
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          overflow: "hidden",
          paddingTop: 20,
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              className="d-flex align-items-center justify-content-center"
              style={{ height: "auto", textAlign: "center" }}
            >
              <img src={toolsIcon} alt="Icon" className="mx-auto" width="70%" />
            </Col>
            <Col
              md={6}
              sm={12}
              className="d-flex align-items-center justify-content-center"
              style={{ height: "auto", padding: 20 }}
            >
              <div>
                <h2 style={{ fontSize: 22 }}>
                  <FaCircle style={{ color: "red", marginRight: 5 }} />{" "}
                  {translations[language].workshop}
                </h2>
                <p>{translations[language].workshopDescription}</p>
                <h2 style={{ fontSize: 22 }}>
                  <FaCircle style={{ color: "red", marginRight: 5 }} />{" "}
                  {translations[language].computer}
                </h2>
                <p>{translations[language].computerDescription}</p>
                <h2 style={{ fontSize: 22 }}>
                  <FaCircle style={{ color: "red", marginRight: 5 }} />{" "}
                  {translations[language].tires2}
                </h2>
                <p>{translations[language].tires2Description}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 0, marginBottom: 20 }}>
            <Col>
              <p style={{ margin: 0, textTransform: "uppercase" }}>
                {translations[language].gallery}
              </p>
              <h2 style={{ fontWeight: 300 }}>
                {translations[language].galleryTitle}
              </h2>
            </Col>
          </Row>
          <Gallery />
        </Container>
      </div>
      <Options />
    </>
  );
}

export default Services2;
