import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../assets/svg/header/background_03.svg";
import tiresIcon from "../assets/svg/icons/tires.svg";
import engineIcon from "../assets/svg/icons/engine.svg";
import gearsIcon from "../assets/svg/icons/gears.svg";
import pcIcon from "../assets/svg/icons/pc.svg";
import cars from "../assets/svg/icons/cars.svg";

import car1 from "../assets/svg/icons/car1.svg";
import car2 from "../assets/svg/icons/car2.svg";

import Services2 from "./Services2";

import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";

function Services({ textShow }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  return (
    <>
      <Container>
        <Row style={{ marginTop: 30, marginBottom: 30 }}>
          <Col>
            {!textShow && (
              <>
                <p style={{ margin: 0, textTransform: "uppercase" }}>
                  {translations[language].weOffer}
                </p>
                <h2 style={{ fontWeight: 300 }}>
                  {translations[language].offers}
                </h2>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginTop: 10,
          backgroundSize: "cover",
          padding: 30,
          // kitos reikiamos stiliaus savybės
        }}
      >
        <Container>
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <Row>
              <Col md={4} sm={4}>
                <div className="d-block d-sm-none text-center">
                  <img width="250" src={car1} />
                </div>
                <div style={{ marginTop: 30 }} className="services-wrapper">
                  <div className="services-circle">
                    <span className="service-icon">
                      <img
                        src={tiresIcon}
                        alt="Icon"
                        style={{ width: "80px", height: "80px" }}
                      />
                    </span>
                  </div>
                  <div style={{ marginLeft: "1px" }} className="services-text">
                    <p
                      style={{
                        color: "#fff",
                        padding: 0,
                        fontSize: 22,
                        lineHeight: 0,
                      }}
                    >
                      {translations[language].tireStorage}
                    </p>
                    <div style={{ color: "#fff" }}>
                      {translations[language].stock}
                      <br />
                      {translations[language].safe}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: -20 }} className="services-wrapper">
                  <div className="services-circle">
                    <span className="service-icon">
                      <img
                        src={engineIcon}
                        alt="Icon"
                        style={{ width: "80px", height: "80px" }}
                      />
                    </span>
                  </div>
                  <div style={{ marginLeft: "1px" }} className="services-text">
                    <p
                      style={{
                        color: "#fff",
                        padding: 0,
                        fontSize: 22,
                        lineHeight: 0,
                      }}
                    >
                      {translations[language].engine}
                    </p>
                    <div style={{ color: "#fff" }}>
                      {translations[language].engine_1}
                      <br />
                      {translations[language].engine_2}
                      <br />
                      {translations[language].engine_3}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="d-block d-sm-none text-center">
                  <img width="250" src={car2} />
                </div>
                <div style={{ marginTop: 30 }} className="services-wrapper">
                  <div className="services-circle">
                    <span className="service-icon">
                      <img
                        src={pcIcon}
                        alt="Icon"
                        style={{ width: "80px", height: "80px" }}
                      />
                    </span>
                  </div>
                  <div style={{ marginLeft: "1px" }} className="services-text">
                    <p
                      style={{
                        color: "#fff",
                        padding: 0,
                        fontSize: 22,
                        lineHeight: 0,
                      }}
                    >
                      {translations[language].pc}
                    </p>
                    <div style={{ color: "#fff" }}>
                      {translations[language].pc_1}
                      <br />
                      {translations[language].pc_2} <br />
                      {translations[language].pc_3}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: -20 }} className="services-wrapper">
                  <div className="services-circle">
                    <span className="service-icon">
                      <img
                        src={gearsIcon}
                        alt="Icon"
                        style={{ width: "80px", height: "80px" }}
                      />
                    </span>
                  </div>
                  <div style={{ marginLeft: "1px" }} className="services-text">
                    <p
                      style={{
                        color: "#fff",
                        padding: 0,
                        fontSize: 22,
                        lineHeight: 0,
                      }}
                    >
                      {translations[language].gear}
                    </p>
                    <div style={{ color: "#fff" }}>
                      {translations[language].gear_1}
                      <br />
                      {translations[language].gear_2}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="d-none d-sm-block">
                  <span style={{ zIndex: 3 }} className="cars">
                    <img src={cars} alt="Icon" width="420px" />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Services2 />
    </>
  );
}

export default Services;
