import React, { useState, useEffect, useContext, useRef } from "react";
import Menu from "./Menu";
import axios from "axios";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";
import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";
import Cards from "./Cards";
import About from "./About";
import { MdDone, MdClose } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";

const Header = ({ hideContent, tires, pcscan, aboutUs, notFound }) => {
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisiblePrice, setIsModalVisiblePrice] = useState(false);
  const [isModalVisibleHotel, setIsModalVisibleHotel] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const height = hideContent ? "12vh" : "85vh";
  const bgHeight = hideContent ? "80px" : "50vh";
  const bgImage = hideContent
    ? "#fff"
    : "url(https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fbackground_02-min.png?alt=media&token=cded20fe-e65f-4377-b342-a43bf399f63e)";

  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [kenitalla, setKenitalla] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = {
      firstName,
      email,
      kenitalla,
      phoneNumber,
      message,
    };

    try {
      await axios.post("/.netlify/functions/sendEmail", formData);
      console.log("Forma sėkmingai išsiųsta");
      setIsModalVisible(false);
      setIsModalVisible2(true);
      setFirstName("");
      setEmail("");
      setKenitalla("");
      setPhoneNumber("");
    } catch (error) {
      console.error("Klaida siunčiant formą", error);
      setIsModalVisible3(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
    console.log("hello");
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleModalOpen2 = () => {
    setIsModalVisible2(true);
    console.log("hello");
  };

  const handleModalClose2 = () => {
    setIsModalVisible2(false);
  };

  const handleModalClose3 = () => {
    setIsModalVisible3(false);
  };

  const handleModalOpenPrice = () => {
    setIsModalVisiblePrice(true);
    console.log("hello");
  };

  const handleModalCloseHotel = () => {
    setIsModalVisibleHotel(false);
    setIsModalVisiblePrice(false);
    console.log("hello");
  };

  const handleModalOpenHotel = () => {
    setIsModalVisibleHotel(true);
    setIsModalVisiblePrice(false);
    console.log("hello");
  };

  const handleModalClosePrice = () => {
    setIsModalVisiblePrice(false);
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
    setLanguage(selectedLanguage);
  }, [selectedLanguage, setLanguage]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTextVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsImageVisible(true);
    }, 600);
    return () => clearTimeout(timer);
  }, []);

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) {
      const width = myRef.current.getBoundingClientRect().width;
      console.log(`Elemento plotis: ${width}px`);
    }
  }, []);

  return (
    <>
      <header
        style={{
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fbackground.jpg?alt=media&token=b10591ab-ee67-4ad2-a39c-1152802bf4d4')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: height,
          transition: "0.5s",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Menu />
        <Container fluid style={{ marginLeft: "6%", marginRight: "6%" }}>
          <Row>
            <div
              style={{ marginTop: "16vh" }}
              className="d-block d-sm-none"
            ></div>
            {!hideContent && (
              <Col>
                <div className="d-block d-sm-none" style={{ marginTop: "10%" }}>
                  <span
                    style={{
                      opacity: isTextVisible ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      background: "linear-gradient(#ef0303 0%, #a32525 100%)",
                      color: "#fff",
                      fontFamily: "Lexend",
                      paddingLeft: 15,
                      overflow: "hidden",
                      paddingRight: 15,
                      fontSize: 30,
                      textTransform: "uppercase",
                    }}
                  >
                    {translations[language].title}
                  </span>
                  <br />
                  <span
                    style={{
                      overflow: "hidden",
                      opacity: isTextVisible ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      color: "#fff",
                      background: "#000",
                      fontFamily: "Lexend",
                      fontSize: 12,
                      marginRight: 10,
                      textTransform: "uppercase",
                    }}
                  >
                    <p>{translations[language].description}</p>
                  </span>
                </div>
                <div className="d-none d-sm-block" style={{ marginTop: "10%" }}>
                  <span
                    style={{
                      opacity: isTextVisible ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      background: "linear-gradient(#ef0303 0%, #a32525 100%)",
                      color: "#fff",
                      fontFamily: "Lexend",
                      paddingLeft: 15,
                      paddingRight: 15,
                      fontSize: 60,
                      textTransform: "uppercase",
                    }}
                  >
                    {translations[language].title}
                  </span>
                  <br />
                  <span
                    style={{
                      opacity: isTextVisible ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      background: "#000",
                      color: "#fff",
                      textAlign: "center",
                      justifyContent: "center",
                      fontFamily: "Lexend",
                      paddingLeft: 15,
                      paddingRight: 15,
                      fontSize: 18.1,
                      scale: 1.2,
                      textTransform: "uppercase",
                    }}
                  >
                    {translations[language].description}
                  </span>
                </div>
                <div
                  style={{
                    opacity: isTextVisible ? 1 : 0,
                    flex: "row",
                    marginTop: 10,
                    zIndex: 4,
                    position: "absolute",
                  }}
                >
                  <Button
                    onClick={handleModalOpen}
                    style={{ marginRight: 10 }}
                    variant="titan_button"
                    disabled={!isTextVisible}
                  >
                    <AiOutlineFieldTime
                      style={{ marginRight: 5, color: "#fff" }}
                      size={22}
                    />
                    <span style={{ color: "#fff" }}>
                      {translations[language].buttons.book}
                    </span>
                  </Button>
                  <Button
                    onClick={handleModalOpenPrice}
                    variant="titan_button"
                    disabled={!isTextVisible}
                  >
                    {" "}
                    <BsCardList
                      style={{ color: "#fff", marginBottom: 2, marginRight: 3 }}
                      size={20}
                    />{" "}
                    <span style={{ color: "#fff" }}>
                      {translations[language].buttons.price}
                    </span>
                  </Button>
                </div>

                <Modal show={isModalVisible} onHide={handleModalClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {translations[language].buttons.book}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="firstName">
                        <Form.Label>
                          {translations[language].modal.firstName}
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder={translations[language].modal.firstName2}
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginTop: 6 }} controlId="email">
                        <Form.Label>
                          {translations[language].modal.email}
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder={translations[language].modal.email2}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        style={{ marginTop: 6 }}
                        controlId="kenitalla"
                      >
                        <Form.Label>
                          {translations[language].modal.kenitalla2}
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder={translations[language].modal.kennitala}
                          value={kenitalla}
                          onChange={(e) => setKenitalla(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        style={{ marginTop: 6 }}
                        controlId="phoneNumber"
                      >
                        <Form.Label>
                          {translations[language].modal.phoneNumber}
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder={translations[language].modal.phoneNumber}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group style={{ marginTop: 6 }} controlId="message">
                        <Form.Label>
                          {translations[language].modal.message}
                        </Form.Label>
                        <Form.Control
                          required
                          as="textarea"
                          rows={3}
                          placeholder={translations[language].modal.message2}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </Form.Group>
                      <br />
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <BeatLoader color="#ffffff" size={10} />
                          ) : (
                            translations[language].modal.submit // Čia naudojamas tiesiogiai tekstas iš vertimų
                          )}
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={isModalVisibleHotel}
                  onHide={handleModalCloseHotel}
                  title="Book Information"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {translations[language].tireStorage}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col style={{ fontWeight: "bold", color: "#000" }}>
                        {translations[language].vehicle}:
                      </Col>
                      <Col style={{ fontWeight: "bold", color: "#000" }}>
                        {translations[language].price}:
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 12″ - 18″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          9.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].cartires2}
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          10.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps2}
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          10.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps} 30″ - 32″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          12.000 ISK
                        </p>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalCloseHotel}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={isModalVisiblePrice}
                  onHide={handleModalClosePrice}
                  title="Book Information"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {translations[language].buttons.price}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col style={{ fontWeight: "bold", color: "#000" }}>
                        {translations[language].vehicle}:
                      </Col>
                      <Col style={{ fontWeight: "bold", color: "#000" }}>
                        {translations[language].price}:
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 14″ - 16″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          10.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 17″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          11.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 18″ - 19″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          14.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 20″ - 21″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          18.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps2} 15″ - 17″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          13.500 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps2} 18″ - 20″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          17.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps} 30″ - 32″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          15.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].jeeps} 33″ - 35″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          19.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].car} 14″ - 16″
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          10.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].balance}
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          7.000 ISK
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p style={{ marginTop: 12 }}>
                          {translations[language].work}
                        </p>
                      </Col>
                      <Col>
                        <p
                          style={{
                            marginTop: 12,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          14.600 ISK
                        </p>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={handleModalClosePrice && handleModalOpenHotel}
                    >
                      Tire storage prices
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleModalClosePrice && handleModalCloseHotel}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={isModalVisible2}
                  onHide={handleModalClose2}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {translations[language].buttons.book}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <MdDone color="#40EA61" size={80} />
                    </div>
                    <div
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      {translations[language].send}
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={isModalVisible3}
                  onHide={handleModalClose3}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {translations[language].buttons.book}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <MdClose color="#40EA61" size={80} />
                    </div>
                    <div
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      {translations[language].error}
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
            )}
          </Row>
        </Container>
      </header>
      <div
        style={{
          position: "absolute",
          top: bgHeight,
          transition: "0.5s",
          height: "100%",
          left: 0,
          right: 0,
          overflow: "hidden",
          zIndex: 1,
          // Added zIndex to make sure this element is on top of background
          background: bgImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      {!hideContent && (
        <div
          className="d-none d-lg-block"
          style={{
            position: "absolute",
            left: 0,
            top: "85px",
            right: 0,
            overflow: "hidden",
            bottom: 0,
            zIndex: 3,
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fcar.png?alt=media&token=e31add6c-a5f6-40d8-9c71-bc45bf9fbe99)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            transition: "transform 1s ease-in-out",
            transform:
              isImageVisible && height
                ? "rotate(-7.55deg) translateX(-50px)"
                : "rotate(-7.55deg) translateX(1200px)",
          }}
        ></div>
      )}
      <div
        style={{
          position: "absolute",
          zIndex: 3,
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Container>
          {notFound && (
            <div style={{ color: "#000" }}>
              <h2>{translations[language].notfound}</h2>
              <p>{translations[language].notfound2}</p>
            </div>
          )}
        </Container>
        <Cards
          textShow={hideContent}
          dekk={tires}
          hideServices={aboutUs}
          scan={pcscan}
        />
        <div style={{ backgroundColor: "#fff", marginTop: 30 }}>
          <About textShow={aboutUs} />
        </div>
      </div>
    </>
  );
};

export default Header;
