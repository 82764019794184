import React, { useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

const Image1 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F1-min.jpg?alt=media&token=f69ce7db-0673-4a8a-a9a6-1efa0b357586";
const Image2 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F2-min.jpg?alt=media&token=b49bf995-b928-4e2f-9edb-ed07d712627c";
const Image3 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F3-min.jpg?alt=media&token=0cf07017-f42e-4a8a-8dcc-958315344e75";
const Image4 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F4-min.jpg?alt=media&token=d7d656d9-3924-453b-9548-a0ade508144c";
const Image5 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F5-min.jpg?alt=media&token=cc23b1a7-4c6b-4a33-8d4a-32002add9ee8";
const Image6 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F6-min.jpg?alt=media&token=68fb6b0a-5f9c-46e4-a02e-2d4b6f6ad740";
const Image7 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F7-min.jpg?alt=media&token=c7db853d-e555-4507-ae8f-b61f3d824e7a";
const Image8 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F8-min.jpg?alt=media&token=80e143d2-a11e-4c43-b803-db2493f33520";
const Image9 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F9-min.jpg?alt=media&token=ffa867e5-b8f6-477c-bb32-d4cf14211fdb";
const Image10 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F10-min.jpg?alt=media&token=a2918768-79e4-4e83-ac35-b82eb03d7916";
const Image11 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F11-min.jpg?alt=media&token=6a78a654-a371-47d6-9468-d6ca0f3457ca";
const Image12 =
  "https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F12-min.jpg?alt=media&token=3871e3d2-4d4b-41db-ace9-1a08eea5782d";

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
];

const App = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    setDirection(direction);
  };

  const slideImages = images.reduce((result, item, index, array) => {
    if (index % 4 === 0) result.push(array.slice(index, index + 4));
    return result;
  }, []);

  const slideImagess = images.reduce((result, item, index, array) => {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col className="d-none d-md-block pb-5">
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              interval={3000}
              controls={false}
            >
              {slideImages.map((slide, index) => (
                <Carousel.Item key={index}>
                  <Row>
                    {slide.map((image, index) => (
                      <Col key={index} xs={12} sm={6} md={3} lg={3}>
                        <img
                          src={image}
                          style={{ borderRadius: 20 }}
                          alt={`Slide ${index}`}
                          className="img-fixed-size d-block w-100 h-100"
                        />
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col className="d-block d-md-none">
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              controls={false}
              interval={2000}
            >
              {slideImagess.map((slide, index) => (
                <Carousel.Item key={index}>
                  <Row>
                    {slide.map((image, index) => (
                      <Col key={index} xs={6} sm={6} md={4} lg={3}>
                        <img
                          src={image}
                          style={{ borderRadius: 20 }}
                          alt={`Slide ${index}`}
                          className="img-fixed-size d-block w-100 h-100"
                        />
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default App;
