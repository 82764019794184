import React, { useContext, useState } from "react";
import { Container, Row, Col, ResponsiveImage } from "react-bootstrap";
import Services from "./Services";

import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";

function About({ textShow, aboutUs }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  return (
    <>
      <Container>
        <Row>
          {!textShow && (
            <>
              <Col className="mt-3">
                <p style={{ marginTop: 0, textTransform: "uppercase" }}>
                  {translations[language].menu.aboutUs}
                </p>
                <h2 style={{ fontWeight: 300 }}>
                  {translations[language].aboutUsTitle}
                </h2>
                <Col
                  sm={12}
                  lg={6}
                  className="d-flex align-items-center justify-content-center d-block d-sm-none"
                >
                  <img
                    style={{
                      paddingRight: "5%",
                      borderTopRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F12-min.jpg?alt=media&token=3871e3d2-4d4b-41db-ace9-1a08eea5782d"
                    width="100%"
                    alt="Titan"
                  />
                </Col>
                <p>{translations[language].aboutUsText}</p>
              </Col>
              <Col
                sm={12}
                lg={6}
                className="d-flex align-items-center justify-content-center d-none d-sm-block mt-3"
              >
                <img
                  style={{
                    paddingRight: "5%",
                    borderTopRightRadius: 300,
                    borderBottomLeftRadius: 300,
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F12-min.jpg?alt=media&token=3871e3d2-4d4b-41db-ace9-1a08eea5782d"
                  width="100%"
                  height="250"
                  alt="Titan"
                />
              </Col>
            </>
          )}
          {aboutUs && (
            <>
              <Col className="mt-3">
                <p style={{ marginTop: 0, textTransform: "uppercase" }}>
                  Þjónustur
                </p>
                <h2 style={{ fontWeight: 300 }}>
                  {translations[language].aboutUsTitle}
                </h2>
                <Col
                  sm={12}
                  lg={6}
                  className="d-flex align-items-center justify-content-center d-block d-sm-none"
                >
                  <img
                    style={{
                      paddingRight: "5%",
                      borderTopRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F12-min.jpg?alt=media&token=3871e3d2-4d4b-41db-ace9-1a08eea5782d"
                    width="100%"
                    alt="Titan"
                  />
                </Col>
                <p>{translations[language].aboutUsText}</p>
              </Col>
              <Col
                sm={12}
                lg={6}
                className="d-flex align-items-center justify-content-center d-none d-sm-block mt-3"
              >
                <img
                  style={{
                    paddingRight: "5%",
                    borderTopRightRadius: 300,
                    borderBottomLeftRadius: 300,
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/images%2F12-min.jpg?alt=media&token=3871e3d2-4d4b-41db-ace9-1a08eea5782d"
                  width="100%"
                  height="250"
                  alt="Titan"
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
      <Services textShow={textShow} hideServices={aboutUs} />
    </>
  );
}

export default About;
