import React, { useContext, useState, useEffect } from "react";
import Logo from "../assets/svg/logo/logo.svg";
import LogoBlack from "../assets/svg/logo/logoBlack.svg";
import { Navbar, Nav, Container, NavDropdown, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaPhoneAlt,
  FaMailBulk,
} from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";
import { Spin as Hamburger } from "hamburger-react";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

function Menu() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  const [isOpen, setOpen] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
    setLanguage(selectedLanguage);
  }, [selectedLanguage, setLanguage]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
  };

  const [expanded, setExpanded] = useState(false);

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  const handleClearActive = () => {
    setActive(null);
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 25) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const [active, setActive] = useState("/");

  const handleClick = (route) => {
    setActive(route);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Navbar
      className={`navbar-titan1${scrolled ? " scrolled" : ""}`}
      bg={scrolled | expanded ? "#fff" : "transparent"}
      expand="lg"
      onToggle={handleToggle}
      expanded={expanded}
      style={{
        position: "fixed",
        background: expanded | scrolled ? "#fff" : "#000",
        width: "100%",
        paddingTop: expanded | scrolled ? "10px" : "15px",
        transition: "0.5s",
        zIndex: 999,
      }}
    >
      <Container fluid style={{ marginLeft: "6%", marginRight: "6%" }}>
        <Link to="/" onClick={handleClearActive} className="nav-link">
          <Navbar.Brand style={{ marginTop: 0 }} href="#home">
            <img
              src={scrolled | expanded ? LogoBlack : Logo}
              width={scrolled | expanded ? "170px" : "140px"}
              style={{
                transition: "width 0.5s ease-in-out",
              }}
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
        </Link>
        <span className="d-block d-lg-none">
          <Navbar.Toggle
            style={{ border: "none", display: "flex" }}
            aria-controls="basic-navbar-nav"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              style={{
                display: expanded ? "none" : "block",
                transition: expanded ? "0.5s" : "0.5s",
              }}
              className="nav-link-icon"
            >
              <NavDropdown
                title={
                  <BsGlobe
                    size={30}
                    style={{
                      color: scrolled | handleToggle ? "#000" : "#fff",
                      marginTop: 8,
                    }}
                  />
                }
                id="basic-nav-dropdown"
                className="custom-dropdown"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                onClick={(e) => e.stopPropagation()}
              >
                <NavDropdown.Item
                  value="en"
                  onClick={() => setSelectedLanguage("en")}
                >
                  English
                </NavDropdown.Item>
                <NavDropdown.Item
                  value="is"
                  onClick={() => setSelectedLanguage("is")}
                >
                  Iceland
                </NavDropdown.Item>
              </NavDropdown>
              <span className="nav-link-icon-bg">
                <span className="nav-link-icon-text">
                  {language === "is" ? "is" : "en"}
                </span>
              </span>
            </span>
            <div style={{ marginLeft: "10px" }}>
              <Hamburger
                color={scrolled || expanded ? "#000" : "#fff"}
                toggled={isOpen}
                toggle={setOpen}
              />
            </div>
          </Navbar.Toggle>
        </span>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link
              to="/"
              onClick={() => {
                setOpen(false);
                setExpanded(false);
              }}
            >
              <Nav.Link href="#heim">
                {translations[language].menu.home}
              </Nav.Link>
            </Link>
            <Link
              to="/bjonusta"
              onClick={() => {
                setOpen(false);
                setExpanded(false);
              }}
            >
              <Nav.Link href="#bjonusta">
                {translations[language].menu.services}
              </Nav.Link>
            </Link>
            <Link
              to="/dekk"
              onClick={() => {
                setOpen(false);
                setExpanded(false);
              }}
            >
              <Nav.Link href="#dekk">
                {translations[language].menu.tires}
              </Nav.Link>
            </Link>
            <Link
              to="/tolvugreining"
              onClick={() => {
                setOpen(false);
                setExpanded(false);
              }}
            >
              <Nav.Link
                href="#tolvugreining"
                onClick={() => {
                  setOpen(false);
                  setExpanded(false);
                }}
              >
                {translations[language].menu.analysis}
              </Nav.Link>
            </Link>
            <Link
              to="/um-okkur"
              onClick={() => {
                setOpen(false);
                setExpanded(false);
              }}
            >
              <Nav.Link href="#um-okkur">
                {translations[language].menu.aboutUs}
              </Nav.Link>
            </Link>
            <div
              className="d-block d-sm-none"
              style={{
                display: "flex",
                backgroundColor: "#EF0303",
                boxShadow: "0px -5px 6px rgba(0, 0, 0, 0.24)",
                flexDirection: "row",
                justifyContent: "center",
                transition: "0.5s",
                alignItems: "center",
                padding: 10,
              }}
            >
              <a
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: 18,
                }}
                href="tel:+3544541720"
                target="_blank"
              >
                <FaPhoneAlt
                  style={{
                    color: "#fff",
                    fontSize: 15,
                    marginLeft: 15,
                    marginRight: 6,
                  }}
                />
                +354 454 17 20
              </a>
            </div>
            <div
              className="d-block d-sm-none"
              style={{
                display: "flex",
                backgroundColor: "#000",
                marginTop: 5,
                boxShadow: "0px -5px 6px rgba(0, 0, 0, 0.24)",
                flexDirection: "row",
                justifyContent: "center",
                transition: "0.5s",
                alignItems: "center",
                padding: 10,
              }}
            >
              <a
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: 18,
                }}
                href="emailto:info@titan1.is"
                target="_blank"
              >
                <FaMailBulk
                  style={{
                    color: "#fff",
                    fontSize: 15,
                    marginLeft: 15,
                    marginRight: 6,
                  }}
                />
                info@titan1.is
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
        <div className="d-none d-lg-block">
          <Nav className="mr-auto">
            <span
              style={{ color: "#fff" }}
              className="nav-link-icon no-hover d-none d-sm-block d-xs-block"
            >
              <NavDropdown
                title={
                  <BsGlobe
                    style={{
                      color: scrolled | handleToggle ? "#000" : "#fff",
                    }}
                  />
                }
                id="basic-nav-dropdown"
                className="custom-dropdown"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <NavDropdown.Item
                  value="en"
                  onClick={() => setSelectedLanguage("en")}
                >
                  English
                </NavDropdown.Item>
                <NavDropdown.Item
                  value="is"
                  onClick={() => setSelectedLanguage("is")}
                >
                  Iceland
                </NavDropdown.Item>
              </NavDropdown>
              <span className="nav-link-icon-bg">
                <span className="nav-link-icon-text">
                  {language === "is" ? "is" : "en"}
                </span>
              </span>
            </span>
            <>
              <div
                style={{
                  display: "flex",
                  background: "linear-gradient(to bottom, #ef0303, #a32525)",
                  boxShadow: "0px -5px 6px rgba(0, 0, 0, 0.24)",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 15,
                  padding: 10,
                  borderTopLeftRadius: 20,
                }}
              >
                <a
                  href="https://www.facebook.com/Titan1.ehf"
                  target="_blank"
                  alt="Titan1"
                >
                  <FaFacebook
                    style={{ color: "#fff", fontSize: 24, marginRight: 14 }}
                  />
                </a>
                <a href="https://www.instagram.com/titancar/" target="_blank">
                  <FaInstagram style={{ color: "#fff", fontSize: 24 }} />
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#000",
                  WebkitBorderBottomRightRadius: 20,
                  boxShadow: "0px -5px 6px rgba(0, 0, 0, 0.24)",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <a
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: 18,
                  }}
                  href="tel:+3544541720"
                  target="_blank"
                >
                  <FaPhoneAlt
                    style={{
                      color: "#fff",
                      fontSize: 15,
                      marginLeft: 15,
                      marginRight: 6,
                    }}
                  />
                  +354 454 17 20
                </a>
              </div>
            </>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}

export default Menu;
