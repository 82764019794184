const translations = {
  en: {
    menu: {
      home: "Home",
      services: "Services",
      tires: "Tires",
      analysis: "Computer analysis",
      aboutUs: "About Us",
    },
    information: "Information",
    notfound: "Page not found",
    notfound2: "Unfortunately, this page is not available.",
    menuName: "Menu",
    send: "Your message has been sent, we will contact you shortly.",
    error: "Your message was not sent because an error occurred.",
    title: "Car service",
    description: "The most reliable service in the whole country",
    buttons: {
      book: "Book an appointment",
      price: "Price list",
    },
    modal: {
      firstName: "First Name:",
      firstName2: "Enter your name",
      lastName: "Last Name:",
      lastName2: "Enter your last name",
      kenitalla2: "Kennitala:",
      kennitala: "Enter your kennitala",
      phoneNumber: "Enter your phone number",
      email: "E-mail address:",
      email2: "Enter your e-mail address",
      message: "Message:",
      message2: "Enter your message",
      submit: "Send",
    },
    services: {
      inspection: "Preparation for inspection",
      belt: "Timing belt replacement",
      equipment: "Great and new equipment",
      room: "Comfortable waiting room",
      text: "Let's look at the whole car, and make sure it gets an inspection.",
      text2:
        "It is important to change the timing belt in a timely manner in order to avoid large additional costs.",
      text3:
        "All Titan1 workshops are equipped with the latest diagnostic and repair equipment.",
      text4:
        "While we are looking at your car, you can have a hot coffee and free internet connection.",
    },
    car: "Passenger car",
    vehicle: "Vehicle",
    price: "Price (incl. VAT)",
    jeeps: "Jeeps",
    cartires2: "Passenger car with rims",
    jeeps2: "Jepps",
    tireStorage: "Tire storage",
    tireStorageD:
      "Tires will be stored in dry and clean rooms, placed properly on specialized shelves and racks to ensure their best possible condition preservation.",
    offers: "Titan1 offers various services",
    weOffer: "We offer",
    stock: "We stock tires",
    safe: "Safe and secure",
    balance: "Balance mode",
    work: "Work an hour",
    engine: "Engine",
    engine_1: "Engine replacement",
    engine_2: "Timing belt replacement",
    engine_3: "Replace head gasket",
    pc: "Computer scan",
    pc_1: "Programming work in cars",
    pc_2: "Computer analysis",
    pc_3: "Troubleshooting",
    gear: "Transmission",
    gear_1: "Clutch repairs",
    gear_2: "Gearbox repairs",
    workshop: "Car workshop",
    workshopDescription:
      "Our workshop is one of the most comprehensive service centers in the country, providing general services for most car models.",
    computer: "Car computer analysis",
    computerDescription:
      "We use certified equipment recommended by leading car manufacturers.",
    tires2: "Tire replacement",
    tires2Description: "We offer professional tire services for cars.",
    aboutUs: "About Us",
    aboutUsTitle: "Titan1 in Iceland since 2014",
    aboutUsText:
      "Titan1 was founded in 2014 and employs professionals in the field of repairs and maintenance. Our goal is to keep maintenance costs to a minimum. Our customers include individuals and companies, and we also have good partnerships with insurance companies, car clubs, and transportation companies..",
    gallery: "Gallery",
    galleryTitle: "Our photo gallery",
    location: "Location",
    locationTitle: "You can find us",
    contacts: "Contacts",
    contactsTitle: "Write to us",
    servicesTitle: "We provide these services",
    tiresTitle: "Change tires",
    tires_01: "Tire mounting and balancing",
    tires_01_d:
      "Professional tire mounting and balancing, during which old tires are removed from the wheels and new ones are mounted and balanced, ensuring their evenness and stable driving experience.",
    tires_02: "",
    workers: "Tire purchase",
    workers_d:
      "Tire purchasing can be a difficult and cumbersome process, but we will help you find the right tires for your vehicle. Trust our professional advice and choose high-quality tires from our wide selection.",
    coffe: "Tire maintenance",
    coffe_d:
      "Well-maintained tires are important not only for safe driving but also for longer service life. We offer various tire maintenance services, including tire balancing, tire repair, tire retreading, and more.",
    phone: "Book an appointment",
    phone_d:
      "It is possible to book an appointment through Facebook, Instagram or directly from our website.",
    pc_c: "Car diagnostics",
    pc_cc: "Computer diagnostics",
    pc_c_1:
      "Titan1 quickly and reliably detects problems related to the computer in your car.",
    pc_c_2:
      "Cleaning the throttle body is something that should happen regularly considering the service history of the car and how it is driven.",
    pc_c_3: "We take care of maintenance and replacement of the adblue system.",
    pc_c_4: "We diagnose problems with the car's computer collision detection.",
    oilChange: {
      title: "Oil Change",
      description:
        "Oil change is an essential maintenance task in any vehicle.",
    },
    wiperReplacement: {
      title: "Wiper replacement",
      description:
        "Good wipers are necessary to ensure clear vision while driving.",
    },
    shockAbsorberReplacement: {
      title: "Shock absorber replacement",
      description:
        "Good stability is essential in any vehicle for a safe driving experience.",
    },
    wheelBearingReplacement: {
      title: "Wheel bearing replacement",
      description:
        "Wheel bearing replacement is a necessary maintenance task in any vehicle to ensure balance and safe driving.",
    },
    springReplacement: {
      title: "Spring replacement",
      description:
        "Spring replacement is a necessary maintenance task in any vehicle to ensure stable steering.",
    },
    electronicDiagnostic: {
      title: "Electronic",
      description:
        "Electronic diagnostics helps identify any defects related to the electronic systems of the car, such as lights, headlight adjustment, battery, and more.",
    },
    engineDiagnostic: {
      title: "Engine",
      description:
        "Engine diagnostics helps identify any malfunctions or defects related to the car's engine.",
    },
    brakeSystemDiagnostic: {
      title: "Brake System",
      description:
        "Brake system diagnostics helps identify any malfunctions or defects related to the car's braking system.",
    },
    transmissionDiagnostic: {
      title: "Transmission",
      description:
        "Transmission diagnostics helps identify any defects or malfunctions related to the car's transmission, which can cause difficulties in shifting gears or starting the car.",
    },
    greeting: "Hello!",
    time: "Weekdays: from 09:00 to 18:00",
    saturday: "Saturday: from 9:00 to 15:00",
    copyrights: "All rights reserved.",
    buttonText: "Click me!",
  },
  is: {
    menu: {
      home: "Heima",
      services: "Þjónusta",
      tires: "Dekk",
      analysis: "Tölvugreining",
      aboutUs: "Um Okkur",
    },
    information: "Upplýsingar",
    notfound: "Síða ekki fundin",
    notfound2: "Því miður, en þessi síða er ekki tiltæk.",
    menuName: "Matseðillinn",
    send: "Skilaboðin þín hafa verið send, við munum hafa samband við þig fljótlega.",
    error: "Skilaboðin þín voru ekki send vegna þess að villa kom upp.",
    title: "Bílaviðgerðir",
    description: "Traust bílaþjónusta í Reykjavík",
    buttons: {
      book: "Bókaðu tíma",
      price: "Verðlist",
    },
    services: {
      inspection: "Undirbuning fyrir skoðun",
      belt: "Tímareim skipti og tímakeðja",
      equipment: "Núnaður búnaður",
      room: "Þægileg biðstofa",
      text: "Skoðum allan bílinn, og sjáum til þess að hann fái skoðun.",
      text2:
        "Mikilvægt er að skipta tímanlega um tímareim til þess að koma í veg fyrir mikin aukakostnað.",
      text3:
        "Öll verkstæði Titan1 eru búin nýjasta greiningar og viðgerðarbúnaði.",
      text4:
        "Meðan við erum að kíkja á bílin þinn getur þú fengið heit kaffi og fría net tenginguna.",
    },
    modal: {
      firstName: "Fornafn:",
      kenitalla2: "Kennitala:",
      firstName2: "Sláðu inn nafnið þitt",
      lastName: "Eftirnafn:",
      lastName2: "Sláðu inn eftirnafnið þitt",
      kennitala: "Sláðu inn kennitölu þína",
      phoneNumber: "Símanúmer:",
      email: "Netfang:",
      email2: "Sláðu inn netfangið þitt",
      message: "Skilaboð:",
      message2: "Sláðu inn skilaboðin þín",
      submit: "Senda",
    },
    car: "Fólksbíllar",
    vehicle: "Ökutæki",
    jeeps: "Jeppar",
    jeeps2: "Jepplingar",
    tireStorage: "Dekkjahótel",
    tireStorageD:
      "Dekkkararnir verða geymdir í þurrum og hreinum herbergjum, settar rétt á sérhæfða hillur og rétta til að tryggja bestu mögulegu viðhaldstilstand þeirra.",
    stock: "Við geymum dekk",
    balance: "Jafnvægisstilling",
    offers: "Titan1 býður upp á ýmsa þjónustu",
    weOffer: "Við bjóðum",
    safe: "Áreiðanlegt og öruggt",
    engine: "Vél",
    engine_1: "Véla skipti",
    engine_2: "Tímareimarskipti",
    engine_3: "Skipta heddpakkningu",
    pc: "Tölvulestur",
    pc_1: "Forritunarvinna í bílum",
    pc_2: "Tölvugreining",
    pc_3: "Bilanaleit",
    gear: "Sjálfskipting",
    gear_1: "Kúplingsviðgerðir",
    gear_2: "Gírkassasviðgerðir",
    workshop: "Bílaverkstæði",
    workshopDescription:
      "Verkstæði okkar er eitt fullkomnasta þjónustuverkstæði landsins og býður upp á alla almenna þjónustu fyrir flestar gerðir bifreiða.",
    computer: "Tölvugreining bíla",
    computerDescription:
      "Við notum löggiltan búnað sem leiðandi bílaframleiðendur mæla með.",
    tires2: "Dekkjaskipti",
    tires2Description:
      "Við bjóðum faglega hjólbarða-þjónustu fyrir fólksbíla og jeppa.",
    work: "Vinna a klukkutíma",
    aboutUs: "Um okkur",
    aboutUsTitle: "Titan1 á íslandi síðan árið 2014",
    aboutUsText: "",
    gallery: "Gallerí",
    galleryTitle: "Myndasafnið okkar",
    location: "Staðsetning",
    locationTitle: "Þú getur fundið okkur",
    contacts: "Tengiliðir",
    contactsTitle: "Skrifaðu til okkar",
    price: "Verð (með VSK)",
    servicesTitle: "Við veitum þessa þjónustu",
    tiresTitle: "Skipt um dekk",
    cartires2: "Fólksbílar með felgum",
    tires_01: "Dekkjaskipti og jafnvægi",
    tires_01_d:
      "Prafessjonell dekkjuskifti og jafnvægi þar sem gömlum dekkjum er tekið af hjólum og nýjum sett á stað og þau eru jafnvægð, sem tryggir jöfnu keyrslu og stöðugu akstursáhrifum.",
    tires_02: "",
    workers: "Dekkjakaup",
    workers_d:
      "Kaup á dekkjum getur verið erfið og þunglyndislegur ferill, en við hjálpum þér að finna réttu dekk fyrir þitt ökutæki. Treystu faglegum ráðum okkar og veldu háa gæða dekk úr okkar víða úrvali.",
    coffe: "Dekkjaviðhald",
    coffe_d:
      "Vel viðhaldsinnaðar dekk eru mikilvæg ekki aðeins fyrir örugga akstursupplifun heldur einnig fyrir lengri þjónustutíma. Við bjóðum upp á mismunandi þjónustur í dekkjahaldi, þar á meðal jafnvægisskilun, dekkjavélar, dekkjavinnslu og fleira.",
    phone: "Panta tíma",
    phone_d:
      "Við bjóðum einnig upp á dekkjageymslu þjónustu til að hjálpa þér að forðast dekkjaskemmdir eða tap. Okkar dekkjageymslur eru öruggar, hreinar og þurrar, svo þín dekk verða vel varðveitt til síðarri notkunar.",
    pc_c: "Bilanagreiningar bíla",
    pc_cc: "Tölvugreining",
    pc_c_1:
      "Titan1 finnur fljótt og örugglega vandamál sem tengjast tölvunni í bílnum þínum.",
    pc_c_2:
      "Að hreinsa hvarfakútinn er eitthvað sem ætti að gerast reglulega með tilliti til þjónustusögu bílsins og hvernig honum er ekið.",
    pc_c_3: "Sjáum um viðhald og skipti á adblue kerfið.",
    pc_c_4: "Greinum vandamál með álesti á tölvu bílsins.",
    greeting: "Labas!",
    oilChange: {
      title: "Olíuskipti",
      description: "Olíuskipti er nauðsynlegt viðhaldsfall í ökutækinu.",
    },
    wiperReplacement: {
      title: "Viftuskifti",
      description:
        "Góð vifta er nauðsynleg til að tryggja örugga og skýra sjón í bílnum.",
    },
    shockAbsorberReplacement: {
      title: "Stöðuskifti",
      description:
        "Góð stöðugleiki er nauðsynlegur í ökutækinu til að tryggja örugga akstursupplifun.",
    },
    wheelBearingReplacement: {
      title: "Hjólaþrengsluskifti",
      description:
        "Hjólaþrengsluskifti er nauðsynlegt viðhaldsfall í ökutækinu til að tryggja jafnvægi og örugga keyrslu.",
    },
    springReplacement: {
      title: "Fjaðurstangaskipti",
      description:
        "Fjaðurstangaskipti er nauðsynlegt viðhaldsfall í ökutækinu til að tryggja að bílstjóri hafi stöðugt stýri á bílnum.",
    },
    electronicDiagnostic: {
      title: "Rafhlaða",
      description:
        "Rafhlaða greining hjálpar við að finna galla sem tengjast rafhlaða kerfum á bílnum, svo sem lýsingu, stillingu á lýsingu, rafhlöðu og fleira.",
    },
    engineDiagnostic: {
      title: "Vél",
      description:
        "Vél greining hjálpar við að finna galla eða skemmdir sem tengjast bílsviði bílins.",
    },
    brakeSystemDiagnostic: {
      title: "Braka Kerfi",
      description:
        "Braka kerfi greining hjálpar við að finna galla eða skemmdir sem tengjast braka kerfi bílsins.",
    },
    transmissionDiagnostic: {
      title: "Túrbína",
      description:
        "Túrbína greining hjálpar við að finna galla eða skemmdir sem tengjast girðikerfi bílsins, sem geta valdið erfiðleikum við girðaskipti eða rafstuðning.",
    },
    time: "Virka daga: frá 09:00 til 18:00",
    saturday: "Laugardagur: frá 9:00 til 15:00",
    copyrights: "Öll réttindi áskilin.",
    buttonText: "Spausk mane!",
  },
};

export default translations;
