import React, { useEffect, useState } from "react";
import { LanguageProvider } from "./LanguageContext";
import Header from "./components/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/global.scss";
import "animate.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <LanguageProvider>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
              </>
            }
          />
          <Route
            path="/bjonusta"
            element={
              <>
                <Header hideContent={true} hideContent2={true} aboutUs={true} />
              </>
            }
          />
          <Route
            path="/dekk"
            element={
              <>
                <Header
                  hideContent={true}
                  hideContent2={true}
                  tires={true}
                  aboutUs={true}
                />
              </>
            }
          />
          <Route
            path="/tolvugreining"
            element={
              <>
                <Header
                  hideContent={true}
                  hideContent2={true}
                  aboutUs={true}
                  tires={false}
                  pcscan={true}
                />
              </>
            }
          />
          <Route
            path="/um-okkur"
            element={
              <>
                <Header hideContent={true} aboutUs={false} />
              </>
            }
          />
          <Route
            path="*"
            element={
              <Header hideContent={true} aboutUs={false} notFound={true} />
            }
          />
        </Routes>
        <br />
        <br />
        <br />
      </LanguageProvider>
    </Router>
  );
}

export default App;
