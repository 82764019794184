import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../LanguageContext";
import translations from "../locale/translations";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/svg/logo/logo.svg";

const Footer = () => {
  const location = useLocation();

  const { language, setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <footer
        style={{
          background: "linear-gradient(to bottom, #000 20%, #5A5A5A)",
          borderTop: "4px solid #D53636",
          paddingTop: 20,
          paddingBottom: 20,
          marginTop: 20,
        }}
      >
        <Container>
          <Row>
            <Col
              lg={4}
              sm={12}
              className="d-flex align-items-center justify-content-center p-3"
            >
              <img src={Logo} />
            </Col>

            <Col lg={4} sm={12}>
              <h5
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  color: "#fff",
                  textTransform: "uppercase",
                }}
              >
                {translations[language].information}
              </h5>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                Viðarhöfði 3, Reykjavík
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                {translations[language].time}
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                {translations[language].saturday}
              </p>
            </Col>
            <Col lg={4} sm={12}>
              <h5
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  color: "#fff",
                  textTransform: "uppercase",
                }}
              >
                {translations[language].menuName}
              </h5>
              <div>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#fff",
                    }}
                  >
                    {translations[language].menu.home}
                  </div>
                </Link>
                <Link
                  style={{
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#fff",
                  }}
                  to="/bjonusta"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#fff",
                    }}
                  >
                    {translations[language].menu.services}
                  </div>
                </Link>
                <Link
                  style={{
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#fff",
                  }}
                  to="/dekk"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#fff",
                    }}
                  >
                    {translations[language].menu.tires}
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/tolvugreining"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#fff",
                    }}
                  >
                    {translations[language].menu.analysis}
                  </div>
                </Link>
                <Link
                  style={{
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#fff",
                  }}
                  to="/um-okkur"
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#fff",
                    }}
                  >
                    {translations[language].menu.aboutUs}
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <footer
        style={{
          backgroundImage: "linear-gradient(to bottom, #ef0303, #a32525)",
        }}
        className="text-light"
      >
        <Container>
          <Row className="justify-content-between" style={{ padding: 10 }}>
            <Col>
              &copy; {new Date().getFullYear()} Titan1.{" "}
              {translations[language].copyrights}
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
